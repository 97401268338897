<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>From</label>
          <flat-pickr
            v-model="tgl_from"
            :value="statusFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>To</label>
          <flat-pickr
            v-model="tgl_to"
            :value="planFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    flatPickr,
  },
  props: {
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      tgl_from: `${new Date(new Date().getFullYear(), new Date().getMonth(), 2).toJSON().slice(0, 10).replace(/-/g, '/')}`,
      // tgl_to: `${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toJSON().slice(0, 10).replace(/-/g, '/')}`,
      tgl_to: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
